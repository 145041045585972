import React, { useState } from "react"
import { Category } from "../../../models/base/category.interface"
import parse from "html-react-parser"
import { CategoryAccordion } from "../../../features/category_accordion/CategoryAccordion"
import { Helmet } from "react-helmet"

interface MacroCategoryProps {
  category: Category
}

export const MacroCategory: React.FC<MacroCategoryProps> = ({ category }) => {
  const [activeKeys, setActiveKeys] = useState<any[]>([category?.id.toString()])

  function handleClick(eventKey: string, isActive: any) {
    if (isActive) {
      setActiveKeys(activeKeys?.filter((id) => id != eventKey))
    } else {
      setActiveKeys([...activeKeys, eventKey])
    }
  }
  return (
    <>
      <Helmet>
        <title>{category.details.title}</title>
        <meta
          name="description"
          content={
            category.details.html.length > 155
              ? `${category.details.html.slice(0, 152)}...`
              : category.details.html
          }
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview: large"
        />
      </Helmet>
      <div className="container custom-container">
        <div className="d-flex flex-column align-items-center justify-content-center py-3">
          <div className="row w-100">
            <div
              className="col-12 my-3 height-250 center-background w-100 p-0 custom-rounded"
              style={{
                background: `url(${category?.imageUrl})`,
              }}
            ></div>

            <div className="col-12 mt-3 mb-2">
              <h1 className="dark-color font-bolder font-33">
                {category?.details.title}
              </h1>
            </div>

            <div className="col-12 mt-2 mb-3">
              <h2 className="gray-color font-16">
                {category?.details.subtitle}
              </h2>
            </div>

            <div className="col-12 my-3">
              <CategoryAccordion
                category={category}
                activeKeys={activeKeys}
                handleClick={handleClick}
              />
            </div>

            <div className="col-12 my-3">
              <div className="gray-color font-16">
                {parse(category!.details.html)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import { useState, useEffect, useCallback } from "react"
import { Shop } from "../../models/base/shop.interface"
import { Carousel } from "react-bootstrap"
import { ShopRating } from "./ShopRating"
import { ReviewFieldInput } from "./ReviewFieldInput"
import { registerPlugin } from "react-filepond"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { debounce } from "lodash"
import {
  selectSendReviewFormData,
  updateProductReview,
  updateShopReview,
} from "../../pages/send_review/sendReviewSlice"
import { useSendTraceEventMutation } from "../../app/services/base/tracer"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import "filepond/dist/filepond.min.css"
import { useSearchParams } from "react-router-dom"
import { ReviewFieldTextarea } from "./ReviewFieldTextarea"
import { ReviewFieldCheck } from "./ReviewFieldCheck"
import shopPlaceholderLogo from "../../assets/images/thumb_placeholder.png"
import { getShopLogo } from "../../utils/shopUtils"
import { InvitationOrder } from "../../models/base/invitationOrder.interface"
import {
  updateTitle,
  updateDescription,
  updateShopScores,
} from "../../pages/send_review/sendReviewSlice"
import { send } from "vite"
// Define the types for the plugins
type Plugin = {
  default: any
}
interface StepOneProps {
  slide: number
  shop?: Shop
  order?: InvitationOrder
  changeSlide: (stepTo: "prev" | "next") => void
}

export const StepOne: React.FC<StepOneProps> = ({
  slide,
  shop,
  changeSlide,
  order,
}) => {
  const [sendTraceEvent] = useSendTraceEventMutation()
  const TrackReviewsInputField = useCallback(
    debounce((e, order) => {
      let x = 1 + 1
      if (order.id) {
        sendTraceEvent({
          event: "review_text_input",
          data: {
            field: e.target.name,
            value: e.target.value,
            id: order.id,
            type: "invitation",
          },
        })
      }
    }, 700),
    [],
  )
  const TrackTitleInputField = useCallback(
    debounce((textValue, order) => {
      let x = 1 + 1
      if (order.id) {
        sendTraceEvent({
          event: "review_text_input",
          data: {
            field: "title",
            value: textValue,
            id: order.id,
            type: "invitation",
          },
        })
      }
    }, 700),
    [],
  )
  useEffect(() => {
    Promise.all<Plugin>([
      import("filepond-plugin-file-validate-type"),
      import("filepond-plugin-file-validate-size"),
      import("filepond-plugin-file-encode"),
      import("filepond-plugin-image-preview"),
    ]).then(
      ([
        { default: FilePondPluginFileValidateType },
        { default: FilePondPluginFileValidateSize },
        { default: FilePondPluginFileEncode },
        { default: FilePondPluginImagePreview },
      ]) => {
        registerPlugin(
          FilePondPluginFileValidateType,
          FilePondPluginFileValidateSize,
          FilePondPluginFileEncode,
          FilePondPluginImagePreview,
        )
      },
    )
  }, [])
  const [searchParams, setSearchParams] = useSearchParams()
  const start_val = searchParams.get("start_val")
  const dispatch = useAppDispatch()
  const formData = useAppSelector(selectSendReviewFormData)
  const [titleValue, setTitleValue] = useState<string | undefined>(undefined)
  const [descriptionValue, setDescriptionValue] = useState<string | undefined>(
    undefined,
  )
  const [shopScores, setShopScoresState] = useState<any>({})
  const [isMedia, setIsMedia] = useState<boolean>(false)

  useEffect(() => {
    if (order?.partial_review) {
      if (order.partial_review.title) {
        setTitleValue(order.partial_review.title)
      }
      if (order.partial_review.description) {
        setDescriptionValue(order.partial_review.description)
      }
      if (order.partial_review.detail_rating) {
        let scores = { ...order.partial_review.detail_rating }
        // convert all the scores to numbers
        Object.keys(scores).forEach((key) => {
          scores[key] = parseInt(scores[key])
        })
        setShopScoresState(scores)
      }
    } else if (order && (order?.partial_review == undefined && start_val) != undefined) {
      let over_scores = { "Lo consiglieresti a qualcuno?": start_val }
      setShopScoresState(over_scores)
      if (order) {
        sendTraceEvent({
          event: "review_rating_input",
          data: {
            field: "Lo consiglieresti a qualcuno?",
            value: start_val,
            type: "invitation",
            id: order.id,
          },
        })
      }
    }
  }, [order, start_val])

  function renderReviewCardInputs() {
    const renderScores = {
      "Chiarezza sito web": {
        infoText:
          "Il sito web visitato è chiaro e di semplice navigabilità nel suo complesso? Hai trovato facilmente ciò che cercavi?",
      },
      "Valutazione prodotto/servizio": {
        infoText:
          "Come valuti il prodotto o il servizio acquistato in ordine alla qualità e al prezzo?",
      },
      "Servizio di pagamento": {
        infoText:
          "E' stato facile effettuare il pagamento? Hai trovato il metodo di pagamento che preferisci?",
      },
      "Consegna/Reso": {
        infoText: "La consegna è avvenuta entro i tempi promessi?",
      },
      "Assistenza clienti": {
        infoText:
          "Se ne hai usufruito, gli operatori dell'assistenza clienti sono stati disponibili e cortesi? La problematica esposta è stata risolta? Se hai dovuto effettuare un reso, come è andata l'operazione in termini di semplicità e velocità?",
      },
      // "Lo consiglieresti a qualcuno?": {
      //   infoText:
      //     "Sulla base della tua esperienza generale di acquisto consiglieresti a qualcuno questo ecommerce?",
      // },
    }
    const entries = Object.entries(renderScores)
    return entries.map(([key, value], index) => (
      <ReviewFieldInput
        key={key}
        index={index + 1}
        label={key}
        infoText={value.infoText}
        inputName={key}
        reviewType="shop"
        mainRating={false}
        // on change send event to tracer
        onChange={(e) => {
          if (order?.id) {
            sendTraceEvent({
              event: "review_rating_input",
              data: {
                field: key,
                value: e.target.value,
                type: "invitation",
                id: order.id,
              },
            })
          }
        }}
        autocompleteMethod={updateShopScores}
        autocompleteValue={
          shopScores[key] ? { name: key, value: shopScores[key] } : false
        }
      />
    ))
  }

  const handleSelect = (selectedSlide: number) => {
    changeSlide(slide < selectedSlide ? "next" : "prev")
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      {/* <!-- Start eCommerce header --> */}
      <div className="card d-flex flex-row align-items-center justify-content-start w-100 p-2 mb-2">
        {shop && (
          <div
            style={{ width: "70px", height: "70px" }}
            className="d-flex align-items-center ms-1 me-3 overflow-hidden"
          >
            <img
              src={getShopLogo(shop.id)}
              alt={`Scrivi la tua recensione su ${shop.domain}`}
              width="100%"
              onError={(e) => (e.currentTarget.src = shopPlaceholderLogo)}
            />
          </div>
        )}
        <div className="d-flex flex-column align-items-start justify-content-center step-header gap-1 me-1">
          <h1 className="font-16 dark-color font-bolder step-header-title m-0">
            {shop ? `Scrivi la tua recensione su ${shop.domain}` : ""}
          </h1>
          {/* <?php if($eshop->displayName != $eshop->domain): ?> */}
          <p className="lightgray-txt m-0 line-clamp">
            {shop?.domain ? shop.domain : "Recensisci eCommerce"}
          </p>
        </div>
      </div>
      {/* <!-- End eCommerce header --> */}

      <div className="card w-100 py-1 px-3 py-lg-3 mt-2 mb-4">
        <Carousel
          activeIndex={slide}
          slide={true}
          interval={null}
          controls={false}
          keyboard={false}
          wrap={false}
          touch={false}
          onSelect={handleSelect}
          indicators={false}
        >
          <Carousel.Item className="">
            <ReviewFieldInput
              key={"Lo consiglieresti a qualcuno?"}
              index={false}
              label={"Valuta l'acquisto"}
              infoText={
                "Sulla base della tua esperienza generale di acquisto consiglieresti a qualcuno questo ecommerce?"
              }
              inputName={"Lo consiglieresti a qualcuno?"}
              reviewType="shop"
              mainRating={true}
              // on change send event to tracer
              onChange={(e) => {
                if (order?.id) {
                  sendTraceEvent({
                    event: "review_rating_input",
                    data: {
                      field: "Lo consiglieresti a qualcuno?",
                      value: e.target.value,
                      type: "invitation",
                      id: order.id,
                    },
                  })
                }
              }}
              autocompleteMethod={updateShopScores}
              autocompleteValue={
                shopScores["Lo consiglieresti a qualcuno?"]
                  ? {
                      name: "Lo consiglieresti a qualcuno?",
                      value: shopScores["Lo consiglieresti a qualcuno?"],
                    }
                  : false
              }
            />
            <div className="d-flex flex-column align-items-start justify-content-center">
              <ReviewFieldTextarea
                inputName="description"
                inputLabel="Recensione"
                placeholder="Raccontaci la tua esperienza..."
                minCharacters={10}
                maxCharacters={600}
                onEvent={(e) => {
                  TrackReviewsInputField(e, order)
                  const { name, value } = e.currentTarget
                  if (name != "title" && name != "description") {
                    dispatch(updateProductReview({ name, value }))
                  } else {
                    dispatch(updateShopReview({ name, value }))
                  }
                  if (name == "description") {
                    const dValue =
                      value.substring(0, 47) + (value.length > 47 ? "..." : "")
                    setTitleValue(dValue)
                    if (order?.id) {
                      TrackTitleInputField(dValue, order)
                    }
                  }
                }}
                autocompleteMethod={updateDescription}
                autocompleteValue={descriptionValue}
                orderId={order?.id || undefined}
              />
              <ReviewFieldTextarea
                inputName="title"
                inputLabel="Titolo della recensione"
                placeholder="Dai un titolo alla tua recensione"
                minCharacters={3}
                maxCharacters={50}
                autocompleteValue={titleValue || undefined}
                autocompleteMethod={updateTitle}
                onEvent={(e) => {
                  TrackReviewsInputField(e, order)
                  const { name, value } = e.currentTarget
                  if (name != "title" && name != "description") {
                    dispatch(updateProductReview({ name, value }))
                  } else {
                    dispatch(updateShopReview({ name, value }))
                  }
                }}
              />

              {shop &&
                shop.eshopData.structured_data.anonymous_reviews &&
                shop.eshopData.structured_data.anonymous_reviews == 1 && (
                  <div className="d-flex flex-column mb-4">
                    <ReviewFieldCheck
                      inputName={"isAnonymous"}
                      infoText
                      onEvent={(e) => {
                        if (order?.id) {
                          sendTraceEvent({
                            event: "review_check_input",
                            data: {
                              field: "is_anonymous",
                              value: e.target.checked,
                              type: "invitation",
                              id: order.id,
                            },
                          })
                        }
                      }}
                    />
                  </div>
                )}
            </div>
          </Carousel.Item>
          <Carousel.Item className="mb-5">
            <div
              id="slideOneServices"
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <h2 className="font-23 dark-color font-bolder m-0 my-2">
                Hai quasi fatto...
              </h2>
              {renderReviewCardInputs()}
            </div>
            {slide === 1 && <ShopRating />}
          </Carousel.Item>
        </Carousel>
        <div className="d-flex align-items-center justify-content-center mb-2 gap-1">
          {slide === 1 && (
            <button
              type="button"
              id="slidePrevBtn"
              className="btn btn-outline-gray background-white font-16 font-semi-bolder flex-grow-1 me-1"
              style={{ height: "50px" }}
              onClick={() => changeSlide("prev")}
              // disabled={slide == 0 ? true : false}
            >
              Precedente
            </button>
          )}
          <button
            type="button"
            className="btn btn-success font-16 font-semi-bolder flex-grow-1 ms-1"
            style={{ height: "50px" }}
            onClick={(e) => {
              if (order?.id) {
                console.log(slide.toFixed())
                sendTraceEvent({
                  event: "review_submit",
                  data: {
                    field: "slide_" + slide.toFixed().toString(),
                    value: new Date()
                      .toISOString()
                      .replace("T", " ")
                      .slice(0, 19),
                    type: "invitation",
                    id: order.id,
                  },
                })
              }
              changeSlide("next")
            }}
          >
            Pubblica
          </button>
        </div>
      </div>
    </div>
  )
}

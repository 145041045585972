import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext, useEffect, useState } from "react"
import {
  Accordion,
  AccordionContext,
  Button,
  Card,
  Collapse,
  useAccordionButton,
} from "react-bootstrap"
import { Link, useLocation, useParams } from "react-router-dom"
import useWindowSize from "../../../utils/useWindowSize"
import { useGetCouponsCategoryListQuery } from "../../../app/services/base/coupons"
import { CouponsCategoryList } from "../../../models/base/couponsCategoryList.interface"

const CustomToggle = ({ children, eventKey, callback }: any) => {
  const { activeEventKey } = useContext(AccordionContext)

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  )

  const isCurrentEventKey = activeEventKey === eventKey

  const windowSize = useWindowSize()
  const isMobile = windowSize.width && windowSize.width <= 768

  return (
    <button
      type="button"
      className={`accordion-button bg-transparent lightgray-txt font-16 p-2 px-3 ${
        isMobile ? "plus-accordion-button" : ""
      } ${isCurrentEventKey ? "" : "collapsed"}`}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  )
}

interface CategoriesAccordionFilterProps {
  couponsCategories: CouponsCategoryList
  categorySlug: string | undefined
}

const CategoriesAccordionFilter: React.FC<CategoriesAccordionFilterProps> = ({
  couponsCategories,
  categorySlug,
}) => {
  const parentCategoryKey = Object.entries(couponsCategories.items).find(
    ([key, value]) =>
      Object.keys(value).find((innerKey) => innerKey === categorySlug),
  )

  const parentCategorySlug = parentCategoryKey ? parentCategoryKey[0] : ""
  return (
    <Accordion
      flush
      className="mb-3 mt-2"
      defaultActiveKey={parentCategorySlug}
    >
      {Object.entries(couponsCategories.items).map(([key, value]) => (
        <Card
          key={key}
          bsPrefix="accordion-item"
          className="bg-transparent py-1"
        >
          <Card.Header bsPrefix="accordion-header">
            <CustomToggle eventKey={key}>
              <span className="me-2">
                {value.parent.description}{" "}
                <small>
                  (
                  {couponsCategories.infoCoupons[value.parent.id]
                    ?.tot_coupons || 0}
                  )
                </small>
              </span>
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse
            eventKey={key}
            aria-labelledby={value.parent.description}
          >
            <Card.Body bsPrefix="accordion-body" className="p-3 pt-0">
              <ul className="list-group">
                {Object.entries(value)
                  .filter(([key, value]) => key != "parent")
                  .map(([key, value]) => {
                    let subCategory = value as any
                    return (
                      <li
                        key={key}
                        className="list-group-item bg-transparent border-0 d-flex justify-content-start align-items-center p-0 py-1 gap-2"
                      >
                        <input
                          className="form-check-input check-small"
                          type="checkbox"
                          name="filterCheckboxCategory"
                          checked={key == categorySlug ? true : false}
                          value={key}
                          id={`filterCheckboxCategoryMobile_${key}`}
                          onChange={() => {}}
                        />
                        <Link
                          to={
                            key == categorySlug
                              ? `/coupon/ultimi-coupon`
                              : `/coupon/${key}`
                          }
                          className={`form-check-label lightgray-txt stretched-link sl-thin text-decoration-none waitLink ${
                            key == categorySlug ? "active" : ""
                          }`}
                          data-group="filterCheckboxCategory"
                        >
                          {subCategory.category.description}{" "}
                          <small>({subCategory.coupons})</small>
                        </Link>
                      </li>
                    )
                  })}
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  )
}

export const CouponMobileFilters: React.FC = () => {
  const location = useLocation()
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const { data: couponsCategories } = useGetCouponsCategoryListQuery()
  const { categorySlug } = useParams()

  const windowSize = useWindowSize()
  const isMobile = windowSize.width && windowSize.width <= 768

  function toggleMobileMenu() {
    if (openMenu) {
      document.body.classList.remove("disable-scroll")
    } else {
      document.body.classList.add("disable-scroll")
    }
    setOpenMenu(!openMenu)
  }

  useEffect(() => {
    document.body.classList.remove("disable-scroll")
    setOpenMenu(false)
  }, [location])

  if (!couponsCategories) {
    return <></>
  }

  return (
    <div className={`card ${isMobile ? "border-0" : "border-transparent"}`}>
      {isMobile ? (
        <>
          <Button
            type="button"
            variant=""
            className="btn d-flex align-items-center justify-content-between font-23 font-bolder dark-color px-3"
            style={{ height: "60px" }}
            aria-expanded={openMenu}
            aria-controls="filterMobileMenu"
            onClick={toggleMobileMenu}
          >
            Filtra per categorie
            <span>
              <FontAwesomeIcon icon={["fas", "chevron-right"]} />
            </span>
          </Button>
          <Collapse in={openMenu}>
            <div className="filters-sidepanel">
              <div className="mt-4 p-3">
                <div className="d-flex align-items-center justify-content-between my-3">
                  <h5 className="font-23 font-bolder dark-color m-0">Filtri</h5>
                  <Button
                    type="button"
                    bsPrefix=""
                    variant=""
                    className="btn-close font-20 dark-color p-0"
                    aria-expanded={openMenu}
                    aria-controls="filterMobileMenu"
                    style={{ opacity: "1" }}
                    onClick={toggleMobileMenu}
                  ></Button>
                </div>

                <div className="mt-2">
                  <div className="font-16 font-bolder lightgray-txt m-0 mt-1">
                    Categorie
                  </div>
                  <CategoriesAccordionFilter
                    couponsCategories={couponsCategories}
                    categorySlug={categorySlug}
                  />
                </div>
              </div>
            </div>
          </Collapse>
        </>
      ) : (
        <>
          <div className="font-23 font-bolder dark-color mx-3 mt-4 mb-2">
            Cerca per categoria
          </div>
          <CategoriesAccordionFilter
            couponsCategories={couponsCategories}
            categorySlug={categorySlug}
          />
        </>
      )}
    </div>
  )
}

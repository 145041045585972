import React, { useEffect } from "react"
import "./Home.module.css"
import "../../assets/css/old/home.css"
import { Hero } from "./hero/Hero"
import { SuggestedShops } from "./suggested_shops/SuggestedShops"
import { RecommendedCategories } from "./recommended_categories/RecommendedCategories"
import { CategoriesHighlights } from "./categories_highlights/CategoriesHighlights"
import { LatestReviews } from "./latest_reviews/LatestReviews"
import { CouponHighlights } from "./coupon_highlights/CouponHighlights"
import { CallToActions } from "./call_to_actions/CallToActions"
import { FaqAccordion } from "./faq/FaqAccordion"
import { Helmet } from "react-helmet"
import { Articles } from "../../features/articles/Articles"
import { forceIsOpenTo } from "../../features/search_engine/searchEngineSlice"
import { useAppDispatch } from "../../app/hooks"
import { Row } from "react-bootstrap"

export const Home: React.FC = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(forceIsOpenTo(true))
  }, [])
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Leggi recensioni autentiche e verificate di e-commerce e prodotti, confronta le migliori offerte e ottieni codici sconto su eShoppingAdvisor"
        />
        <meta
          name="keywords"
          content="Scrivere recensioni,leggere recensioni,consultare recensioni,confrontare ecommerce,recensioni ecommerce,recensioni siti internet, ecommerce affidabili, ecommerce sicuri, siti ecommerce sicuri, siti ecommerce affidabili, siti sicuri, siti affidabili, migliori ecommerce, migliori siti, shopping online. "
        />
        <meta
          property="og:title"
          content="Scopri la prima guida di riferimento sullo shopping online | Cerca, confronta, acquista, condividi!"
        />
        <meta
          property="og:description"
          content="Leggi sempre le recensioni degli altri utenti, prima di fare un acquisto su internet e racconta poi la tua esperienza su eShoppingAdvisor."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${import.meta.env.VITE_B2C_BASE_URL}`}
        />
        <meta
          property="og:image:url"
          content={`${import.meta.env.VITE_CDN_BASE_URL}/assets/img/share.png`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:type" content="image/png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@Eshoppingadvis" />
        <meta
          property="twitter:title"
          content="Scopri la prima guida di riferimento sullo shopping online | Cerca, confronta, acquista, condividi!"
        />
        <meta
          property="twitter:description"
          content="Leggi sempre le recensioni degli altri utenti, prima di fare un acquisto su internet e racconta poi la tua esperienza su eShoppingAdvisor."
        />
        <meta
          property="twitter:image"
          content={`${
            import.meta.env.VITE_CDN_BASE_URL
          }/assets/img/twittersharer.png`}
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview: large"
        />
        <link rel="canonical" type="" href={window.location.href} />
        <title>
          Recensioni Verificate dei Migliori Negozi - eShoppingAdvisor
        </title>
        <link rel="canonical" type="" href={window.location.href} />
      </Helmet>
      <Hero />
      <SuggestedShops />
      <RecommendedCategories />
      <LatestReviews />
      <CouponHighlights />
      <CategoriesHighlights />
      <Row className="justify-content-center">
        <div className="col-12 col-lg-8">
          <h1 className="text-center mt-5 dark-color font-22 font-bolder my-3 pt-3 xs-font-14">
            Trova i siti più affidabili per i tuoi acquisti online e cerca le
            migliori offerte del prodotto che sta cercando. <br />
            Leggi le recensioni e condividi la tua opinione.
          </h1>
        </div>
      </Row>
      <CallToActions />
      <Articles />
      <FaqAccordion />
    </>
  )
}
